import React from 'react'
import { insertEvent } from './util/trackingSiteBehavior'
import { Callout } from '@radix-ui/themes'
import { InfoCircledIcon } from '@radix-ui/react-icons'

const AppNavigateConfidence = () => {
  return (
    < div class='no margin' style={{'margin-top':'-95px'}}>
    {/* <!-- HERO */}
		
			<section id="hero-14" class="bg--fixed hero-section division ">
				<div class="container">	
					<div class="row justify-content-center  mt-0">


						{/* <!-- HERO TEXT --> */}
						<div class="col-md-8">
							<div class="hero-14-txt text-center color--white wow animate__animated animate__fadeInUp">

								{/* <!-- Title --> */}
								<h2>Total Self-Confidence </h2>

								{/* <!-- Text --> */}
								<p class="p-xl">Clinically proven insights and solutions for changing the beliefs and thinking patterns robbing you of self-confidence. 


{/* <Callout.Root color='purple' highContrast size="1">
                    
    <Callout.Icon>
        <InfoCircledIcon />
    </Callout.Icon>
      <p class='m-0 small'> Expect pyschological transformation immediately after consuming any of our solution.</p>
</Callout.Root>
*/}



<br></br><br></br>What our solutions treat, other offerings and upcoming releases are below. <em> <br></br>The truth shall set you free.</em></p> 
<p></p>    

								{/* <!-- STORE BADGES -->												 */}
								<div class="stores-badge badge-img-md">

									{/* <!-- AppStore --> */}
									<a href="#start" class="btn btn--theme mr-2">
                                        Conditions We Treat
										{/* <img class="appstore" src="images/store_badges/appstore.png" alt="appstore-badge"/> */}
									</a>
													
									{/* <!-- Google Play --> */}
									<a href="#start" class="btn btn--black">
                                        Other Offerings
										{/* <img class="googleplay" src="images/store_badges/googleplay.png" alt="googleplay-badge"/> */}
									</a> 
							
								</div>	
                                {/* <!-- END STORE BADGES -->	 */}

								{/* <!-- OS Prerequisite --> */}
								<div class="os-version-ext">
	

									<span class="os-version">Expect pyschological transformation immediately after consuming any of our solutions or <a href='/insights/677c342e34bcee92913561a8'><span  class='color--purple'>prescriptions</span></a>.</span>
								</div>
					
							</div>
						</div>	
                        {/* <!-- END HERO TEXT -->	 */}


					</div>   
                     {/* <!-- End row --> 	 */}
				</div>	   
                {/* <!-- End container -->  */}
			</section>	
            {/* <!-- END HERO -->	 */}
    <div class="container">

    
{/* 
    <!-- FEATURES WRAPPER -- */}
    <div class=" py-50 fbox-wrapper text-center">
        {/* <p>Note: We are constantly releasing new content. To be notified of new releases and updates sign up for free <a class="text-info hover--black" href="createaccount" >here.</a></p> */}

        <Callout.Root color='purple' highContrast size="1">

    <Callout.Icon>
        <InfoCircledIcon />
    </Callout.Icon>
      <p id='start' class='m- ' >Note: We are constantly releasing new content. To be notified of new releases and updates sign up for free <a class="text-info hover--black" href="createaccount" >here.</a></p>
</Callout.Root>

    <p class='mb-2 small'><br></br> <em>Every piece of content has a discussion forum where you can get feedback and ask questions.</em></p>
        <div class="row row-cols-2 row-cols-md-3">
            


            {/* <!-- FEATURE BOX #1 --> */}
             <div class="col">
                 <div class="fbox-6 fb-1 wow animate__animated animate__fadeInUp animate__delay-1">

                     {/* <!-- Image --> */}
                    <div class="fbox-img h-180">
                        <img class="img-fluid" src="images/overcome-flag.png" alt="feature-image"/>
                    </div>

                    {/* <!-- Text --> */}
                    <div class="fbox-txt">
                        <h6 class="h6-xl">Overcome a Condition</h6>
                        <p><strong>We have <a class='color' href='/insights/677c342e34bcee92913561a8'>solutions</a> for:</strong> Low self-confidence, Social anxiety, physical anxiety, low self-esteem, self-consciousness, low self-worth,  feeling inadequate, feeling not good enough, feeling inferior, and more.
                        </p>
                        {/* <a style={{'margin-right': '5px'}}href={``} class="btn  btn-md r-36 btn--tra-black hover--tra-black" onClick={() =>
          {
            insertEvent('click',{'button':'therapy',"objectName":`conditions`})
           
       }}>Get Therapy</a>  */}

<a href={`/conditions`} class="btn mt-1 r-36 btn--black btn-sm hover--tra-black" onClick={() =>
          {
            insertEvent('click',{'button':'/conditions'})
         
       }}>See our solutions</a> 
                    </div>

                 </div>
             </div>	
             {/* <!-- END FEATURE BOX #1 -->	 */}


             {/* <!-- FEATURE BOX #2 --> */}
             <div class="col">
                 <div class="fbox-6 fb-2 wow animate__animated animate__fadeInUp animate__delay-2">

                     {/* <!-- Image --> */}
                    <div class="fbox-img h-180">
                        <img class="img-fluid" src="images/belief-word.png" alt="feature-image"/>
                    </div>

                    {/* <!-- Text --> */}
                    <div class="fbox-txt">
                        <h6 class="h6-xl">Dismantle a belief</h6>
                        <p><strong>Beliefs we cover:</strong> Something is wrong with me, I'm flawed, Some people are inferior...We will be releasing more dismantle-belief solutions in the future. 
                        <a class="text-danger hover--tra-black" href='/createaccount'> Create an account</a> to be notified.
                        </p>

                        <a style={{'margin-right': '5px'}}href={`/beliefsearch`} class="btn mt-1 btn-sm r-36 btn--black hover--tra-black" onClick={() =>
          {
            insertEvent('click',{'button':'dismantleBeliefs',"objectName":`beliefs`})
           
       }}>Dismantle a Belief</a> 
                    </div>

                 </div>
             </div>	
             {/* <!-- END FEATURE BOX #2 -->	 */}


             {/* <!-- FEATURE BOX #3 --> */}
             <div class="col">
                 <div class="fbox-6 fb-3 wow animate__animated animate__fadeInUp animate__delay-3">

                     {/* <!-- Image --> */}
                    <div class="fbox-img h-180">
                        <img class="img-fluid" src="images/therapy.png" alt="feature-image"/>
                    </div>

                    {/* <!-- Text --> */}
                    <div class="fbox-txt">
                        <h6 class="h6-xl">Get 1on1 Therapy</h6>
                        <p>If you'd like individual support on anything, we're here to help. The results are better performance at work and life.
                        </p>
                        <a style={{'margin-right': '5px'}}href={`/insights/677d58ee34bcee92913561ab`} class="btn mt-1  btn-sm r-36 btn--black hover--tra-black" onClick={() =>
          {
            insertEvent('click',{'button':'dismantle belief',"objectName":`beliefs`})
           
       }}>Learn More</a> 
                    </div>

                 </div>
             </div>
             	{/* <!-- END FEATURE BOX #3 -->	 */}

                 {/* <!-- FEATURE BOX #4 --> */}
             <div class="col">
                 <div class="fbox-6 fb-4 wow animate__animated animate__fadeInUp animate__delay-3">

                     {/* <!-- Image --> */}
                    <div class="fbox-img h-180">
                        <img class="img-fluid" src="images/chains.png" alt="feature-image"/>
                    </div>

                    {/* <!-- Text --> */}
                    <div class="fbox-txt">
                        <h6 class="h6-xl">Break Free from Worldy Constructs</h6>
                        <p>We call these <a href='/insights/6711426f17c988b343ec8077' class='text-info hover--tra-black'>loaded terms.</a> They are words like smart, ugly, stupid, that can unconsciously cause unhappiness and negative emotions.
                        </p>

                        <a style={{'margin-right': '5px'}} href='/loadedWordSearch' class="btn mt-1  btn-sm r-36 btn--black hover--tra-black" onClick={() =>
          {
            insertEvent('click',{'button':'loadedwords',"objectName":`loadedwords`})
           
       }}>Search for a loaded term</a> 
                    </div>

                 </div>
             </div>
             	{/* <!-- END FEATURE BOX #4 -->	 */}

                {/* <!-- FEATURE BOX #5 --> */}
             <div class="col">
                 <div class="fbox-6 fb-5 wow animate__animated animate__fadeInUp animate__delay-3">

                     {/* <!-- Image --> */}
                    <div class="fbox-img h-180">
                        <img class="img-fluid" src="images/mentalgrowth.png" alt="feature-image"/>
                    </div>

                    {/* <!-- Text --> */}
                    <div class="fbox-txt">
                        <h6 class="h6-xl">Improve Your Thinking</h6>
                        <p>Unique insights on anxiety, the psychology of purpose, performance anxiety, and high-performance. 
                        </p>
                        <a style={{'margin-right': '5px'}}href={`/insights`} class="btn mt-1 btn-sm r-36 btn--black hover--black" onClick={() =>
          {
            insertEvent('click',{'button':'insights',"objectName":`insights`})
           
       }}>Access Critical Insights</a> 
                    </div>
                    

                 </div>
             </div>
             	{/* <!-- END FEATURE BOX #5 -->	 */}

                 {/* <!-- FEATURE BOX #56 --> */}
             <div class="col">
                 <div class="fbox-6 fb-6 wow animate__animated animate__fadeInUp animate__delay-3">

                     {/* <!-- Image --> */}
                    <div class="fbox-img h-180">
                        <img class="img-fluid" src="images/dating.png" alt="feature-image"/>
                    </div>

                    {/* <!-- Text --> */}
                    <div class="fbox-txt">
                        <h6 class="h6-xl">Confidence in Dating</h6>
                        <p>To find your perfect partner you must be brutally honest with yourself and shed beliefs you unknowingly and knowingly picked up in the past. 
                        </p>
                        <a style={{'margin-right': '5px'}}href={`/insights/6782bb25a432901a3a981462`} class="btn mt-1 btn-sm r-36 btn--black hover--black" onClick={() =>
          {
            insertEvent('click',{'button':'therapy',"objectName":`datingHelp`})
           
       }}>Get Dating Help</a> 
                    </div>
                    

                 </div>
             </div>
             	{/* <!-- END FEATURE BOX #5 -->	 */}


                
        </div>  
        {/* <!-- End row -->   */}
    </div>	
    {/* <!-- END FEATURES WRAPPER --> */}

    <h3 class=" text-center mt-3" >Now Available </h3>	
    <p class='mb-2 small'><br></br> <em>Note: Prescription is referring to pyschological prescriptions you can learn more about <a class='color--purple' href='/insights/677c342e34bcee92913561a8'>here</a>.</em></p>

    <p><strong>Prescriptions for</strong>:</p>
    <ul class="simple-list">

									<li class="list-item">
										<p>
                                       <a class="color--purple" href="/condition/66def843d50bf12e82770ae3"> Overcoming an Intelligence Insecurity </a> 
										</p>
									</li>
                                    <li class="list-item">
										<p>
                                            <a class="color--purple" href='/condition/6796d31270586ae0c6a2189b'>Finding Your Purpose</a>  
										</p>
									</li>
                                    

	</ul>
    <h3 class=" text-center mt-3" >Coming Soon </h3>	
    <p><strong>Prescriptions for</strong>:</p>
    <ul class="simple-list">

									
                                    <li class="list-item">
										<p>
                                       Performance Anxiety 
										</p>
									</li>
                                    <li class="list-item">
										<p>
                                       Handling Pressure (Work, Sport, Hobbies, etc)
										</p>
									</li>
                                    <li class="list-item">
										<p>
                                        Attraction Insecurities
										</p>
									</li>
                                    <li class="list-item">
										<p>
                                         Catastrophising aka What if thinking
										</p>
									</li>

	</ul>
    <p><strong>New Content:</strong> </p>
    <ul class="simple-list">

									<li class="list-item">
										<p>
                                        More <a class="text-info hover--black" href="/insights/673e12e3e73b19869f085c48">real-life examples</a>  of how to uncover and eliminate a physical anxiety trigger.
										</p>
									</li>

	</ul>
								
								<br></br>
								<p></p>


</div>  




</div>


        
  )
}

export default AppNavigateConfidence