import React from 'react'
import { insertEvent } from '../util/trackingSiteBehavior'

const TherapyBenefits = () => {
  return (
    <>


<div class="container mt-3">
    <div class=" text-center">
        <a href="/therapyservice.html" class='btn btn-md btn--theme mt-2  hover--black mb-2'>Get Started</a>
     

    </div>

     {/* <!-- SECTION TITLE -->	 */}
     <div class="row ">	
        <div class="">
            <div class=" mb-20">	

                {/* <!-- Title -->	 */}
                <h4 class="h2-title">Benefits of relinquishing beliefs and thought patterns that lead to negative emotions and unhappiness
                </h4>	
                
            </div>	
        </div>
    </div>



                    {/* <!-- FEATURES WRAPPER --> */}
                    <div class="fbox-wrapper">
                        <div class="row row-cols-1 row-cols-md-3 rows-3">


                            {/* <!-- FEATURE BOX #1 --> */}
                            <div class="col">
                                <div class="fbox-2 fb-1 wow animate__animated animate__fadeInUp animate__delay-1">

                                    

                                    {/* <!-- Text --> */}
                                    <div class="fbox-txt">
                                    <h5>Less Anxiety
                        </h5>
                        <p>We're talking about physical and unhealthy mental anxiety. Anxiety can feel very much like “gut” or “intuition,” which raises the “How do I know that this isn’t my gut telling me something?” Knowing how to uncover the beliefs driving your anxiety eliminates this concern. If it’s intuition, you’ll know because you know how to unpack your emotions.
                        </p>
                        <a href={`/condition/674c8f3390d9df6b72e685e9`} class="btn btn-sm mt-1 r-36 btn--black hover--tra-black" onClick={() =>
                            {
                            insertEvent('click',{'button':'Anxiety Prescription'})

                            }}>See our Anxiety Prescriptions
                        </a> 

                                    </div>

                                </div>
                            </div>
                                {/* <!-- END FEATURE BOX #1 -->	 */}


                            {/* <!-- FEATURE BOX #2 --> */}
                            <div class="col">
                                <div class="fbox-2 fb-2 wow animate__animated animate__fadeInUp animate__delay-2">

                                

                                    {/* <!-- Text --> */}
                                    <div class="fbox-txt">
                                    <h5>Increased Self-Confidence & Self-Esteem</h5>
                        <p>You’ll increase your ability to be ok with yourself regardless of what you have or do. You’ll also improve your ability to step into situations (social and non-social) and be present without falling into a state of introversion or self-consciousness. You’ll worry less about being “awkward” regardless of what others think, be less nervous, and care less about the opinions of others regarding your actions and views.
                        </p>
                        <a href={`/condition/66e44a783deb0fa5b8afb45a`} class="btn btn-sm mt-1 r-36 btn--black hover--tra-black" onClick={() =>
                            {
                            insertEvent('click',{'button':'Self-confidence Prescription'})

                            }}>See our Self-confidence Prescription
                        </a> 
                                    </div>

                                </div>
                            </div>
                                {/* <!-- END FEATURE BOX #2 -->	 */}


                            {/* <!-- FEATURE BOX #3 --> */}
                            <div class="col">
                                <div class="fbox-2 fb-3 wow animate__animated animate__fadeInUp animate__delay-1">

                                

                                    {/* <!-- Text --> */}
                                    <div class="fbox-txt">
                                    <h5>Authenticity & Alignment</h5>
                        <p>An aligned person feels like they’re living according to his or her uniqueness and inner desires. From personal experience, studying the lives of other high-achieving individuals, and researching the topic of mastery, this feeling is the result of identifying and pursuing intrinsically motivating goals regardless of outside discouragement. We'd put foward that living in this manner leads to alignment with the divine and an often fortunate byproduct of alignment is high-performance.
                        </p>

                        <a href={`/insights/677d9422a432901a3a98145e`} class="btn btn-sm mt-1 r-36 btn--black hover--tra-black" onClick={() =>
                            {
                            insertEvent('click',{'button':'Self-esteem Prescription'})

                            }}>Learn More
                        </a> 
                       
                                    </div>

                                </div>
                            </div>
                                {/* <!-- END FEATURE BOX #3 -->	 */}


                            {/* <!-- FEATURE BOX #4 --> */}
                            <div class="col">
                                <div class="fbox-2 fb-4 wow animate__animated animate__fadeInUp animate__delay-2">

                                    

                                    {/* <!-- Text --> */}
                                    <div class="fbox-txt">
                                    <h5>Increased Emotional Intelligence/Mastery</h5>
                        <p> Emotional mastery is a skill we believe and have found everyone can improve. The more proficient one is, the more capable they are of understanding and eliminating the root cause(s) of their negative emotions.
                        </p>
                        <a style={{'margin-right': '5px'}}href={`/insights/677d9e56a432901a3a98145f`} class="btn mt-1  btn-sm r-36 btn--black hover--tra-black" onClick={() =>
{
insertEvent('click',{'button':'dismantle belief',"objectName":`beliefs`})

}}>Learn More</a> 
                                    </div>

                                </div>
                            </div>	
                            {/* <!-- END FEATURE BOX #4 -->	 */}


                            {/* <!-- FEATURE BOX #5 --> */}
                            <div class="col">
                                <div class="fbox-2 fb-5 wow animate__animated animate__fadeInUp animate__delay-1">

                                

                                    {/* <!-- Text --> */}
                                    <div class="fbox-txt">
                                    <h5>More self-awareness</h5>
                        <p>Being purposed demands self-awareness. We define self-awareness as an understanding of one’s thoughts, beliefs, emotions, and desires. Self-awareness allows you to assess if you're living according to personal or outside standards you don't care for. This awareness lets you live differently and let go of limiting beliefs.
                        </p>
                        {/* <a href={`/conditions#66e45a353deb0fa5b8afb45b`} class="btn btn-sm mt-1 r-36 btn--black hover--tra-black" onClick={() =>
                            {
                            insertEvent('click',{'button':'Self-esteem Prescription'})

                            }}>See our Self-esteem Prescription
                        </a>  */}
                                    </div>

                                </div>
                            </div>	
                            {/* <!-- END FEATURE BOX #5 -->	 */}

 {/* <!-- FEATURE BOX #5 --> */}
 <div class="col">
                                <div class="fbox-2 fb-6 wow animate__animated animate__fadeInUp animate__delay-1">

                                

                                    {/* <!-- Text --> */}
                                    <div class="fbox-txt">
                                    <h5>Clarity of Purpose</h5>
                        <p> Helping you understand and demystify what purpose is. Questions we answer: “What is my purpose?”, “How do I find my purpose”, “Am I living out my purpose?”
                        </p>
                        <a href={`/condition/6796d31270586ae0c6a2189b`} class="btn btn-sm mt-1 r-36 btn--black " onClick={() =>
                            {
                            insertEvent('click',{'button':'Find my purpose Prescription'})

                            }}>See our How to Find My Purpose Prescription
                        </a> 
                                    </div>

                                </div>
                            </div>	
                            {/* <!-- END FEATURE BOX #56 -->	 */}



                            


                        </div> 
                         {/* <!-- End row -->   */}
                    </div>	
                    {/* <!-- END FEATURES WRAPPER --> */}


                </div> 
</>


  )
}

export default TherapyBenefits